import React from 'react';
import PropTypes from 'prop-types';
import { Button, Stack } from '@mui/material';

const ShoppingCartStepperNavigation = ({ onBack, children }) => (
  <Stack
    direction="row"
    justifyContent={{ xs: 'space-around', md: 'space-between' }}
    alignItems="flex-end"
    width="100%"
    flexGrow={1}
  >
    <Button variant="underlineLink" onClick={onBack} sx={{ height: 40 }}>
      Atrás
    </Button>
    {children}
  </Stack>
);

ShoppingCartStepperNavigation.propTypes = {
  onBack: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ShoppingCartStepperNavigation;
