import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const ShoppingCartSummaryActionButton = ({
  tooltipTitle,
  loading,
  label,
  ...props
}) => (
  <Tooltip title={tooltipTitle}>
    <span>
      <LoadingButton
        variant="contained"
        size="small"
        color="primary"
        sx={{
          width: { xs: 120, md: 150 },
          fontSize: { xs: 10, md: 13 },
          height: { xs: 30, md: 40 },
        }}
        loading={loading}
        {...props}
      >
        {label}
      </LoadingButton>
    </span>
  </Tooltip>
);

ShoppingCartSummaryActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  tooltipTitle: PropTypes.string,
  loading: PropTypes.bool,
};

ShoppingCartSummaryActionButton.defaultProps = {
  tooltipTitle: null,
  loading: false,
};

export default ShoppingCartSummaryActionButton;
